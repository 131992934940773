import React, { useState } from "react";
import "./LandingPage.scss";
import bannerImage from "../../Assets/Images/mainImage.png";
import AboutImage from "../../Assets/Images/aboutImage.png";
import ServiceIcon from "../../Assets/Images/serviceIcon.png";
import PurpleIcon from "../../Assets/Images/purpleIcon.png";
import OrangeIcon from "../../Assets/Images/orangeIcon.png";
import BlueIcon from "../../Assets/Images/blueIcon.png";
import PurpleImg from "../../Assets/Images/purpleImage.png";
import OrangeImg from "../../Assets/Images/orangeImage.png";
import BlueImg from "../../Assets/Images/blueImage.png";
import UserImg from "../../Assets/Images/headeruser.png";
import Event1 from "../../Assets/Images/EventImage1.png";
import Event2 from "../../Assets/Images/EventImage2.png";
import Event3 from "../../Assets/Images/EventImage3.png";
import { CgCloseO } from "react-icons/cg";
import {
  DoubleQoute,
  PurpleArrow,
  OrangeArrow,
  BlueArrow,
  LightBlueArrow,
} from "../../Assets/icons/Icons";
import Buttons from "../../Components/Buttons/Buttons";
import LandingHeader from "../../Components/LandingHeader/LandingHeader";
import LandingFooter from "../../Components/LandingFooter/LandingFooter";
import { ContactUs } from "../../utils/apiCalls";
import axios from "axios";

const formDetails = {
  name: "",
  email: "",
  mobileNumber: "",
  message: "",
};

const LandingPage = () => {
  const [formData, setFormData] = useState(formDetails);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
      isValid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.email.trim() || !emailPattern.test(formData.email)) {
      formErrors.email = "Invalid email";
      isValid = false;
    }

    const mobilePattern = /^[0-9]{8,16}$/;
    if (
      !formData.mobileNumber.trim() ||
      !mobilePattern.test(formData.mobileNumber)
    ) {
      formErrors.mobileNumber = "Invalid mobile number";
      isValid = false;
    }

    if (!formData.message.trim()) {
      formErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoader(true);
      const form = {
        name: formData.name,
        mobileNo: formData.mobileNumber,
        email: formData.email,
        message: formData.message,
      };
      try {
        const response = await axios.post(
          "https://api.myaisquad.com/api/v1/useraccess/contactUs",
          form
        );
        if (response.status === 200) {
          setIsLoader(false);
          setShowPopup(true);
          setFormData(formDetails);
        } else {
          setIsLoader(false);
          alert("Failed to submit contact form");
        }
      } catch (error) {
        setIsLoader(false);
        console.error("Error submitting contact form:", error);
        alert("Error submitting contact form");
      }
    }
    setIsLoader(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const features = [
    {
      title: "Uncompromised Data Security",
      text: "Shameo AI securely stores your documents in AWS S3, ensuring high availability and reliability. For enhanced searchability and organization, we utilize vector embeddings with chunking in Vector DB Supabase, optimizing the retrieval of relevant data and insights.",
    },
    {
      title: "Advanced Vector Embedding with GPT API",
      text: "Shameo AI leverages GPT APIs for state-of-the-art vector embedding and output correction, delivering finely-tuned answers at scale. Whether you're working with large datasets or complex queries, Shameo AI ensures accurate and contextually relevant responses through seamless integration with advanced AI models.",
    },
    {
      title: "API-First Integration for Easy Deployment",
      text: "Our platform offers a robust API that integrates effortlessly into any environment. With a simple API key, you can embed Shameo AI's powerful features into your existing infrastructure, enabling fast, secure, and flexible deployments across various applications.",
    },
    {
      title: "Seamless Integration with Leading LLMs",
      text: "Shameo AI supports integration with top public and private large language models (LLMs), including GPT, Gemini, Palm, Llama, and Anthropic. This vendor-agnostic approach allows flexibility in LLM deployment and management across diverse use cases.",
    },
    {
      title: "Protection Against Data Poisoning and Leakage",
      text: "With features like role-based access control (RBAC) and output filtering, Shameo AI prevents unauthorized access and ensures that your AI models are safeguarded from data poisoning and leakage.",
    },
    {
      title: "Real-Time Monitoring and Security",
      text: "Our platform offers real-time monitoring and anomaly detection, tracking both model performance and security metrics through a single-pane dashboard. This proactive approach enables fast detection of any unusual behaviors, ensuring robust security and performance.",
    },
  ];

  return (
    <>
      <LandingHeader />
      <div className="home">
        <div className="home_banner" id="home">
          <div className="home_banner_cont">
            <div className="home_banner_cont_top">
              <h1>Shameo AI </h1>
              <h2>A Leading AI Agent Platform</h2>
              <p>
                Shameo AI is a next-generation AI platform, delivering secure,
                customizable, and easy-to-use AI solutions for enterprises.
                Combining comprehensive security features with intuitive user
                control, Shameo AI ensures that businesses can leverage
                cutting-edge AI without compromising data integrity, regulatory
                compliance, or operational simplicity.
              </p>
            </div>
            {/* <div className="home_banner_cont_bottom">
              <img src={bannerImage} alt="AI Robo" />
            </div> */}
          </div>
        </div>
        <div className="home_about" id="about">
          <div className="home_about_section">
            {/* <div className="home_about_section_left">
              <img src={AboutImage} alt="About" />
            </div> */}
            <span>About Us</span>
            <div className="home_about_section_right">
              
              <div className="home_about_section_right_one">
                <h2>WhiteFish, LLC</h2>
                <p>
                  WhiteFish, LLC is a Boston based women and minority owned AI
                  service company. Our Shameo AI agent platform provides
                  uncompromised AI security, and easy-to-use AI agent services
                  that are under total user control.
                </p>
              </div>
              <div className="home_about_section_right_two">
                <h2>Our Mission</h2>
                <p>
                  Transform learning and working with Shameo AI personalized assistants.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="home_service" id="service">
          <div className="home_service_cont">
            <div className="home_service_cont_heading">
              <span>Services</span>
              <h2>
                Revolutionize Customer Interactions with Advanced AI Solutions
              </h2>
              <p>
                We provide cutting-edge AI solutions, including custom and
                AI-powered chatbots designed to revolutionize your customer
                interactions.
              </p>
            </div>
            <div className="home_service_cont_cards">
              <div className="home_service_cont_cards_card">
                <img src={ServiceIcon} alt="Service Icon" />
                <h3>AI Chatbots for automated, real-time responses</h3>
                <p>
                  AI chatbots deliver automated, real-time responses for
                  seamless customer interactions.
                </p>
              </div>
              <div className="home_service_cont_cards_card">
                <img src={ServiceIcon} alt="Service Icon" />
                <h3>Custom-built chatbots tailored to your Business</h3>
                <p>
                  Custom-built chatbots designed to meet your specific business
                  needs.
                </p>
              </div>
              <div className="home_service_cont_cards_card">
                <img src={ServiceIcon} alt="Service Icon" />
                <h3>Voice Chatbots Service</h3>
                <p>
                  Voice chatbots enable seamless, hands-free communication for
                  effortless customer interactions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="home_key" id="key">
          <div className="home_key_section">
            <div className="home_key_section_top">
              <span>Features</span>
              <h2>Key Features</h2>
              <p>
                Our AI services come with advanced features to ensure the best
                results for your business.
              </p>
            </div>
            <div className="home_key_section_tags">
              {features.map((feature, index) => (
                <div key={index} className="home_key_section_tags_tag">
                  <h4>{feature.title}</h4>
                  <p
                    className={
                      expandedIndexes.includes(index) ? "" : "truncated"
                    }
                  >
                    {feature.text}
                  </p>
                  <span
                    className="read-more"
                    onClick={() => toggleExpand(index)}
                  >
                    {expandedIndexes.includes(index)
                      ? "Read Less"
                      : "Read More"}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="home_benefits">
          <div className="home_benefits_section">
            <div className="home_benefits_section_top">
              <span>Use Cases and Benefits</span>
              <p>
                Unlock practical solutions with tailored Shameo AI use cases
                that drive growth and operational efficiency.
              </p>
            </div>
            <div className="home_benefits_section_tags">
              <div className="home_benefits_section_tags_tag">
                <h4>Customer Support and Automation</h4>
                <p>
                  Shameo AI’s advanced NLP and LLM integration enable powerful,
                  secure customer service solutions, providing fast, accurate,
                  and secure responses while ensuring user data is protected.
                </p>
              </div>
              <div className="home_benefits_section_tags_tag">
                <h4>Knowledge Management and Optimization</h4>
                <p>
                  Enterprises can optimize their knowledge bases using Shameo
                  AI’s automated content curation and real-time updates,
                  enhancing the delivery and relevance of AI-generated insights.
                </p>
              </div>
              <div className="home_benefits_section_tags_tag">
                <h4>Process Automation Across Operations</h4>
                <p>
                  Real-time data processing and workflow automation streamline
                  enterprise operations, allowing for more efficient
                  decision-making and resource management.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="home_new">
          <div className="home_new_benefits">
            <div className="home_new_benefits_section">
              <div className="home_new_benefits_section_top">
                <span>Limitations and Scale</span>
                <p>
                  Shameo AI is designed to scale, supporting deployments across
                  various environments. However, very large-scale or highly
                  specialized deployments may require additional customization
                  and configuration to optimize performance and security.
                </p>
              </div>
            </div>
          </div>
          <div className="home_new_benefits">
            <div className="home_new_benefits_section">
              <div className="home_new_benefits_section_top">
                <span>Integration Capabilities:</span>
                <p>
                  Shameo AI provides seamless API integration with external
                  services and enterprise systems, allowing smooth interaction
                  across web and mobile platforms. The platform supports
                  connection to multiple LLMs, ensuring flexibility and
                  adaptability for enterprise AI deployment.
                </p>
              </div>
            </div>
          </div>
          <div className="home_new_benefits">
            <div className="home_new_benefits_section">
              <div className="home_new_benefits_section_top">
                <span>What Makes Shameo AI Stand Out?</span>
                <p>
                  Shameo AI stands out through its combination of powerful
                  security features, intuitive user interface, and comprehensive
                  control options. Its ability to integrate with multiple LLMs,
                  protect against AI-specific security threats, and scale across
                  environments makes it a top choice for enterprises seeking a
                  secure, scalable, and easy-to-use AI platform.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="home_llm">
          <div className="home_llm_cont">
            <div className="home_llm_cont_heading">
              <span>Large Language Models</span>
              <h2>Unlock the power of AI with Shameo LLM</h2>
              <p>
                Our LLM-based services leverage the latest advancements in AI to
                provide intelligent, context-aware responses, enabling more
                efficient and accurate communication.
              </p>
            </div>
            <div className="home_llm_cont_sec">
              <div className="home_llm_cont_sec_card1">
                <div className="home_llm_cont_sec_card1_left">
                  <img src={PurpleIcon} alt="Icon" />
                  <h2>Customize the AI LLM to build your own use cases</h2>
                  <p>
                    Customize Shameo LLMs to align with your unique business
                    requirements, creating personalized conversational models
                    and tailored interactions for enhanced user experiences.
                  </p>
                  <ul>
                    <li>
                      <span className="icon">
                        <PurpleArrow />
                      </span>
                      <span>
                        Tailor LLMs to fit your specific business requirements
                        and workflows.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <PurpleArrow />
                      </span>
                      <span>
                        Create personalized conversational models that align
                        with your brand's voice.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <PurpleArrow />
                      </span>
                      <span>
                        Adapt the LLM's capabilities to handle unique tasks or
                        industry-specific needs.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <PurpleArrow />
                      </span>
                      <span>
                        Customize responses and interactions to enhance customer
                        satisfaction.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="home_llm_cont_sec_card1_right">
                  <img src={PurpleImg} alt="Icon" />
                </div>
              </div>
              <div className="home_llm_cont_sec_card2">
                <div className="home_llm_cont_sec_card2_left">
                  <img src={OrangeIcon} alt="Icon" />
                  <h2>Why Choose Shameo LLM for Your Business</h2>
                  <p>
                    Shameo LLMs are revolutionizing how businesses interact with
                    their customers, offering dynamic, conversational
                    experiences that can be fine-tuned to meet specific needs.
                  </p>
                  <ul>
                    <li>
                      <span className="icon">
                        <OrangeArrow />
                      </span>
                      <span>
                        LLMs offer intelligent, real-time responses, enhancing
                        customer communication.{" "}
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <OrangeArrow />
                      </span>
                      <span>
                        They understand context, enabling natural and engaging
                        conversations.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <OrangeArrow />
                      </span>
                      <span>
                        Scalable and multilingual, they are perfect for global
                        customer support.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <OrangeArrow />
                      </span>
                      <span>
                        Customizable and efficient, LLMs provide accurate
                        solutions tailored to your business needs.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="home_llm_cont_sec_card2_right">
                  <img src={OrangeImg} alt="Icon" />
                </div>
              </div>
              <div className="home_llm_cont_sec_card3">
                <div className="home_llm_cont_sec_card3_left">
                  <img src={BlueIcon} alt="Icon" />
                  <h2>Advanced AI Solutions</h2>
                  <p>
                    We offer a range of AI-driven services designed to
                    streamline business operations and elevate customer
                    experiences through innovative technology.
                  </p>
                  <ul>
                    <li>
                      <span className="icon">
                        <BlueArrow />
                      </span>
                      <span>
                        AI-powered automation to optimize workflows and reduce
                        manual tasks.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <BlueArrow />
                      </span>
                      <span>
                        Data-driven insights for smarter decision-making and
                        strategic growth.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <BlueArrow />
                      </span>
                      <span>
                        Custom AI models tailored to specific business
                        challenges and objectives.
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <BlueArrow />
                      </span>
                      <span>
                        Seamless integration with existing systems for a smooth
                        transition to AI-powered processes.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="home_llm_cont_sec_card3_right">
                  <img src={BlueImg} alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_price" id="price">
          <div className="home_price_cont">
            <div className="home_price_cont_heading">
              <span>Pricing</span>
              <h2>Plans That Fit Your Needs</h2>
              <p>
                Shameo AI offers a range of pricing plans tailored to different
                enterprise needs. For more information on custom pricing and
                deployment options, enterprises are encouraged to contact Shameo
                AI directly.
              </p>
            </div>
            <div className="home_price_cont_sec">
              <div className="home_price_cont_sec_plan">
                <h3>Plan A</h3>
                <p className="text">
                  <strong>$1,000</strong>
                  <small>/month</small>
                </p>
                <h5>Billed Annually</h5>
                <p>Unlimited access to all features.</p>
                <a href="https://enterprise.myaisquad.com/" target="_blank">
                  <Buttons variant="primary">Get Started</Buttons>
                </a>
                <ul>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>
                      It will provide 500M Input and 200M Output Tokens per
                      month.
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>
                      It will provide 6B Input and 2.4B Output Tokens per year.
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>You can Create and Train your own LLM</span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>Retrieving data from LLM</span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>Generate API keys for third-party access.</span>
                  </li>
                </ul>
              </div>
              <div className="home_price_cont_sec_plan">
                <h3>Plan B</h3>
                <p className="text">
                  <strong>$4,950</strong>
                  <small>/month</small>
                </p>
                <h5>Billed Annually</h5>
                <p>Unlimited access to all features.</p>
                <a href="https://enterprise.myaisquad.com/" target="_blank">
                  <Buttons variant="primary">Get Started</Buttons>
                </a>
                <ul>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>
                      It will provide 2.5B Input and 1B Output Tokens per month
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>
                      It will provide 30B Input and 12B Output Tokens per year
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>You can Create and Train your own LLM</span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>Retrieving data from LLM</span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>Generate API keys for third-party access.</span>
                  </li>
                </ul>
              </div>
              <div className="home_price_cont_sec_plan">
                <h3>Premium</h3>
                <p className="text">
                  <small>Contact Us for the Best Pricing</small>
                </p>
                <p>Unlimited access to all features.</p>
                <a href="https://enterprise.myaisquad.com/" target="_blank">
                  <Buttons variant="primary">Get Started</Buttons>
                </a>
                <ul>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>
                      It will provide Input and Output Tokens Custom Price
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>You can Create and Train your own LLM</span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>Retrieving data from LLM</span>
                  </li>
                  <li>
                    <span className="icon">
                      <LightBlueArrow />
                    </span>
                    <span>Generate API keys for third-party access.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="home_test">
          <div className="home_test_section">
            <div className="home_test_section_top">
              <span>Testimonials</span>
              <h2>What Our Clients Say</h2>
            </div>
            <div className="home_test_section_clients">
              <div className="home_test_section_clients_card">
                <div className="home_test_section_clients_card_top">
                  <DoubleQoute className="qoute" />
                  <p>
                    "These AI chatbots transformed our customer support, making
                    it more efficient and aligned with our brand. Highly
                    recommended!"
                  </p>
                  <div className="arrow"></div>
                </div>
                <div className="home_test_section_clients_card_bottom">
                  <img src={UserImg} alt="Client" />
                  <h3>Jos Davies</h3>
                </div>
              </div>
              <div className="home_test_section_clients_card">
                <div className="home_test_section_clients_card_top">
                  <DoubleQoute className="qoute" />
                  <p>
                    "The voice chatbots streamlined our client interactions,
                    enhancing the overall experience. Very satisfied with the
                    outcome."
                  </p>
                  <div className="arrow"></div>
                </div>

                <div className="home_test_section_clients_card_bottom">
                  <img src={UserImg} alt="Client" />
                  <h3>Emily Renolds</h3>
                </div>
              </div>
              <div className="home_test_section_clients_card">
                <div className="home_test_section_clients_card_top">
                  <DoubleQoute className="qoute" />
                  <p>
                    "Tailored LLM chatbots have greatly improved our customer
                    engagement. Impressive results and excellent support!"
                  </p>
                  <div className="arrow"></div>
                </div>
                <div className="home_test_section_clients_card_bottom">
                  <img src={UserImg} alt="Client" />
                  <h3>David Lang</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_event" id="blog">
          <div className="home_event_section">
            <div className="home_event_section_heading">
              <span>Events</span>
              <h2>Blogs & Events</h2>
            </div>
            <div className="home_event_section_cards">
              <div className="home_event_section_cards_card">
                <div className="home_event_section_cards_card_image">
                  <img src={Event1} alt="Event" />
                </div>
                <div className="home_event_section_cards_card_content">
                  <h2>The Future of AI Chatbots</h2>
                  <p>
                    AI chatbots are transforming how businesses communicate with
                    customers. They offer real-time support, automate responses,
                    and enhance user satisfaction effortlessly.
                  </p>
                </div>
              </div>
              <div className="home_event_section_cards_card">
                <div className="home_event_section_cards_card_image">
                  <img src={Event2} alt="Event" />
                </div>
                <div className="home_event_section_cards_card_content">
                  <h2>Benefits of Custom Chatbots for Your Business</h2>
                  <p>
                    Custom chatbots allow businesses to offer personalized
                    interactions. With tailored responses and intelligent
                    workflows, they deliver unique experiences that suit your
                    brand.
                  </p>
                </div>
              </div>
              <div className="home_event_section_cards_card">
                <div className="home_event_section_cards_card_image">
                  <img src={Event3} alt="Event" />
                </div>
                <div className="home_event_section_cards_card_content">
                  <h2>How Voice Chatbots Are Changing Customer Service</h2>
                  <p>
                    Voice chatbots enable hands-free interactions, making
                    customer service faster and more efficient. As voice
                    technology evolves, businesses can provide instant,
                    conversational support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_contact" id="contact">
          <div className="home_contact_cont">
            <div className="home_contact_cont_heading">
              <p>Contact Us </p>
              <h2>Get In Touch </h2>
              <h2>With Us For More Information</h2>
            </div>
            <div className="home_contact_cont_form">
              <div className="home_contact_cont_form_inputs">
                <div className="data">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="data">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="data">
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                  {errors.mobileNumber && (
                    <span className="error">{errors.mobileNumber}</span>
                  )}
                </div>
              </div>
              <div className="home_contact_cont_form_text">
                <textarea
                  placeholder="Message"
                  rows="4"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <span className="error">{errors.message}</span>
                )}
              </div>
              <div className="home_contact_cont_form_bottom">
                <Buttons variant="secondary" onClick={handleSubmit}>
                  {isLoader ? "Sending..." : "Send Message"}
                </Buttons>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup" onClick={handleClosePopup}>
          <div className="popup_container" onClick={(e) => e.stopPropagation()}>
            <div className="popup_header">
              <h2>Thank You...</h2>
              <div className="close" onClick={handleClosePopup}>
                <CgCloseO />
              </div>
            </div>
            <p>
              Our team will reach out to you via your registered email regarding
              your inquiry.
            </p>
          </div>
        </div>
      )}
      <LandingFooter />
    </>
  );
};

export default LandingPage;
